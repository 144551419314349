<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="register mat-elevation-z12">
    <div class="register-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="200px">
      <img class="logo" src="assets/img/nuvola_logo_white.png">
      <h5 class="panel-title">NuvolaWeb 4.0</h5>
      <nuvolaweb-toolbar-language-selector></nuvolaweb-toolbar-language-selector>
    </div>
    <div class="register-content" fxLayout="column" fxLayoutAlign.gt-sm="start stretch" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <div fxLayout="row" fxFlex="48"></div>
        <div fxLayout="row" fxFlex="52" fxLayoutAlign="start  start" style="font-size: 85%;color:rgba(0, 0, 0, 0.50)">{{'REGISTRATION.DoctorLabel' | transloco}}</div>
      </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
      <form [formGroup]="addressesForm" (ngSubmit)="register()" fxLayout="column" fxFlex="50" >
        <mat-form-field fxFlex>
            <input maxlength="100" matInput placeholder="{{'REGISTRATION.ActivityNamePlaceHolder' | transloco}}"  formControlName="activityName">
          </mat-form-field>
      </form>
      <form [formGroup]="registerForm" (ngSubmit)="register()" fxLayout="column" fxFlex="50" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <mat-form-field fxFlex>
          <input maxlength="50" matInput placeholder="{{'REGISTRATION.LastNamePlaceHolder' | transloco}}" formControlName="lastName" required>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input maxlength="50" matInput placeholder="{{'REGISTRATION.FirstNamePlaceHolder' | transloco}}" formControlName="firstName" required>
        </mat-form-field>
      </form>
      </div>
      <form [formGroup]="addressesForm" (ngSubmit)="register()" fxLayout="column" >
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
          <mat-form-field >
            <input maxlength="255" matInput placeholder="{{'REGISTRATION.AddressPlaceHolder' | transloco}}" formControlName="address" required>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="33%">
            <input maxlength="12" matInput placeholder="{{'REGISTRATION.ZipCodePlaceHolder' | transloco}}" formControlName="zipCode" required (keyup)="onZipCodeInput($event.key)" >
          </mat-form-field>
          <!-- <form [formGroup]="registerForm" (ngSubmit)="register()" fxLayout="column" fxFlex fxFlex.gt-sm="23%">
            <mat-form-field>
              <mat-select formControlName="addressType" >
                <mat-option value="R">
                  {{'REGISTRATION.returnTypeValue' | transloco}}
                </mat-option>
                <mat-option value="S">
                  {{'REGISTRATION.shipTypeValue' | transloco}}
                </mat-option>
                <mat-option value="I">
                  {{'REGISTRATION.invoiceTypeValue' | transloco}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form> -->
        </div>
      </form> 
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
          <form [formGroup]="registerForm" (ngSubmit)="register()" fxLayout="column" fxFlex fxFlex.gt-sm="23%">
            <mat-form-field>
              <input maxlength="20" matInput placeholder="{{'REGISTRATION.VatNumberPlaceHolder' | transloco}}" formControlName="vat" required> 
            </mat-form-field>
          </form> 
          <form [formGroup]="addressesForm" (ngSubmit)="register()" fxLayout="column"  fxFlex fxFlex.gt-sm="23%">
            <mat-form-field >
              <input maxlength="30" matInput placeholder="{{'REGISTRATION.PhonePlaceHolder' | transloco}}" formControlName="phone">
            </mat-form-field>
          </form> 
          <form [formGroup]="addressesForm" (ngSubmit)="register()" fxLayout="column"  fxFlex fxFlex.gt-sm="23%">
            <mat-form-field >
              <input matInput placeholder="{{'REGISTRATION.CityPlaceHolder' | transloco}}" formControlName="city" required>
            </mat-form-field>
          </form> 
          <form [formGroup]="addressesForm" (ngSubmit)="register()" fxLayout="column"  fxFlex fxFlex.gt-sm="23%">
            <mat-form-field >
              <input matInput placeholder="{{'REGISTRATION.CountryPlaceHolder' | transloco}} *" formControlName="countryCode" require maxlength="60">
            </mat-form-field>
          </form>
            <!-- 
              <div fxFlex fxFlex.gt-sm="24%">
                <nuvolaweb-generic-autocomplete-input style="width: 100%;"
            #countryField
            [apiEndPoint]="autocompleteEndpoint"
            [placeHolder]="'REGISTRATION.CountryPlaceHolder'"
            (onOptionSelect)="selectCounty($event)"
            (onClearSelected)="clearSelected()"
            >
            </nuvolaweb-generic-autocomplete-input>
            </div> -->          
        </div>
      <form [formGroup]="registerForm" (ngSubmit)="register()" fxLayout="column">
        <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap.gt-sm="16px">
          <mat-form-field fxFlex fxFlex.gt-sm="66%">
            <input matInput placeholder="{{'REGISTRATION.EmailPlaceHolder' | transloco}}" type="email" required formControlName="email">
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="32%">
            <input matInput placeholder="{{'REGISTRATION.PasswordPlaceHolder' | transloco}}" [type]="hide? 'password' : 'text'" required formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="!registerForm.controls.password.valid && registerForm.controls.password.touched">
              {{'REGISTRATION.PasswordError' | transloco}}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap.gt-sm="16px" style="padding-bottom: 10px;">
          <mat-slide-toggle fxFlex fxFlex.gt-sm="40%" class="mat-slide-toggle-aligners" formControlName="question1"
            (change)="toggleAligners($event)">{{'REGISTRATION.question1' | transloco}} *</mat-slide-toggle>
          <mat-form-field fxFlex fxFlex.gt-sm="60%" *ngIf="showHowManyPatients">
            <mat-select matInput placeholder="{{'REGISTRATION.question1.1' | transloco}}" formControlName="question11">
              <mat-option value="1">{{'REGISTRATION.question1.1.1' | transloco}}</mat-option>
              <mat-option value="2">{{'REGISTRATION.question1.1.2' | transloco}}</mat-option>
              <mat-option value="3">{{'REGISTRATION.question1.1.3' | transloco}}</mat-option>
              <mat-option value="4">{{'REGISTRATION.question1.1.4' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap.gt-sm="16px" style="padding-bottom: 20px;">
          <mat-slide-toggle fxFlex fxFlex.gt-sm="65%" formControlName="question2">{{'REGISTRATION.question2' | transloco}} *</mat-slide-toggle>
          <mat-slide-toggle fxFlex fxFlex.gt-sm="35%" formControlName="question3">{{'REGISTRATION.question3' | transloco}} *</mat-slide-toggle>
        </div> -->

        <div class="extra-options" fxLayout="row" fxLayoutAlign="center center">
          <div class="mat-form-field-wrapper" id='captcha'>
            <re-captcha 
            (resolved)="resolved($event)"
            (error)="errored($event)"
            errorMode="handled"
            required
            ></re-captcha>
            <mat-error *ngIf="!registerForm.controls.captcha.valid && registerForm.controls.captcha.touched">
              {{'REGISTRATION.CaptchaError' | transloco}}
            </mat-error>
          </div>
          <!-- <mat-checkbox class="remember-me">{{'REGISTRATION.Terms.Text' | transloco}} <a href="#">{{'REGISTRATION.Terms.Link' | transloco}}</a></mat-checkbox> -->
        </div>
        <button color="primary" type="submit" [class.spinner]="isLoading" [disabled]="isLoading" mat-raised-button >{{'REGISTRATION.RegistrationButton' | transloco}}</button>
        <p class="note">{{'REGISTRATION.Note' | transloco}} <a [routerLink]="['/']">{{'REGISTRATION.LogInLink' | transloco}}</a></p>
      </form> 
    </div>
  </div>
</div>
