<div class="horizontal-navigation" fxHide fxShow.gt-sm>
  <div class="container">
    <div class="menu menu-wrapper" fxLayout="row">
      <div class="menu-list">
        <div *ngFor="let item of sidenavItems$ | async; let index = index;">
          <div *accessControl="{id:item.getPermission()}">
            <elastic-toolbar-navigation-item *ngIf="index < showMoreButtonAfterIndex"
                                             [item]="item" [currentlyOpen]="currentlyOpen$ | async"></elastic-toolbar-navigation-item>
          </div>
          <div class="more-button-container" fxLayout="row" fxLayoutAlign="start center" *ngIf="index == showMoreButtonAfterIndex">
            <button mat-icon-button class="more-button" [matMenuTriggerFor]="moreMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="navigation-item-container" *ngIf="currentUser.role == 'US' && currentUser.userGroup == 'PJ' || currentUser.role === 'EX' && currentUser.executiveArea === 'P'">
          <a [routerLink]="['/dashboard/list/project-basket']" routerLinkActive="active"
             class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple>
            <mat-icon class="icon">folder_open</mat-icon>
            <span class="name">{{ 'toolbar.ROUTES.Basket' | transloco }}</span>
          </a>
        </div>
        <div class="navigation-item-container" *ngIf="currentUser.role == 'US' && currentUser.userGroup == 'PJ' || currentUser.role === 'EX' && currentUser.executiveArea === 'P'">
          <a [routerLink]="['/dashboard/list/project-waiting-for']" routerLinkActive="active"
             class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple>
            <mat-icon class="icon">alarm</mat-icon>
            <span class="name">{{ 'toolbar.ROUTES.ProjectWaitingFor' | transloco }}</span>
          </a>
        </div>
        <div class="navigation-item-container" *ngIf="currentUser.role == 'EX' &&  currentUser.executiveArea === 'P'">
          <a [routerLink]="['/quotes']" routerLinkActive="active"
             class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple>
            <mat-icon class="icon">edit_note</mat-icon>
            <span class="name">{{ 'toolbar.ROUTES.Quotes' | transloco }}</span>
          </a>
        </div>
      </div>
      <div class="navigation-item-container" *accessControl="{id: ['DO']}">
        <a [routerLink]="['/contents/shopping']" routerLinkActive="active"
           class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple >
          <span class="name">{{ 'toolbar.ROUTES.Contents.Shopping' | transloco }}</span>
          <mat-icon class="icon">shopping_cart</mat-icon>
        </a>
      </div>
      <div class="navigation-item-container" *ngIf="currentUser.role == 'EX' && currentUser.executiveArea === 'N'">
        <a [routerLink]="['/contents/products']" routerLinkActive="active"
           class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple >
          <span class="name">{{ 'toolbar.ROUTES.Contents.Shopping' | transloco }}</span>
          <mat-icon class="icon">shopping_cart</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>

<mat-menu #moreMenu="matMenu" [overlapTrigger]="false">
  <div class="layout gamma" (click)="$event.stopPropagation()" *ngFor="let item of sidenavItems$ | async; let index = index;">
    <div *accessControl="{id:item.getPermission()}">
      <elastic-toolbar-navigation-dropdown-item *ngIf="index >= showMoreButtonAfterIndex" class="dropdown-item-container"
        [item]="item" [currentlyOpen]="currentlyOpen$ | async"></elastic-toolbar-navigation-dropdown-item>
    </div>
  </div>
</mat-menu>
