import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromSidenav from '../../sidenav/shared/sidenav.action';
import * as fromRoot from '../../../reducers/index';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.model';
import { SetCurrentlyOpenByRouteAction } from '../../sidenav/shared/sidenav.action';
import { ContentsService } from 'src/app/pages/contents/services/contents.service';
import {User} from '../../../modules/security/models/user.model';
import {UserService} from '../../../modules/security/services/user.service';

@Component({
  selector: 'elastic-toolbar-navigation',
  templateUrl: './toolbar-navigation.component.html',
  styleUrls: ['./toolbar-navigation.component.scss']
})
export class ToolbarNavigationComponent implements OnInit, OnDestroy {

  sidenavItems$: Observable<SidenavItem[]>;
  currentlyOpen$: Observable<SidenavItem[]>;
  showMoreButtonAfterIndex = 10;
  currentUser: User;

  private _routerEventsSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private cd: ChangeDetectorRef,
    private _contentService: ContentsService,
    private _userService: UserService
  ) { }

  ngOnInit() {

    this.currentUser = this._userService.readUserFromLocalStorage();
    this.sidenavItems$ = this.store.select(fromRoot.getSidenavItems);
    this.currentlyOpen$ = this.store.select(fromRoot.getSidenavCurrentlyOpen);

    this._routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
      }
    });
    this.cd.detectChanges();

    // Define Menu Items here
    // If we store sideNavItems in store - skip init

    this.changeLanguageTrigger();
  }

  public changeLanguageTrigger() {
    const Dashboard = new SidenavItem({
      name: 'toolbar.ROUTES.Dashboard',
      icon: 'dashboard',
      route: '/dashboard',
      subItems: [],
      position: 1,
      forPermission: ['AD', 'EX', 'US', 'DO', 'AG', 'SA', 'DI']
    });

    const TreatmentSideItem =
      new SidenavItem({
        name: 'toolbar.ROUTES.Treatments',
        route: '/treatments',
        icon: 'assignment',
        subItems: [],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        },
        forPermission: ['AD', 'EX', 'US', 'DO', 'AG', 'SA', 'DI']
      });

    const ContentSideItem = new SidenavItem({
      name: 'toolbar.ROUTES.Contents.Contents',
      icon: 'source',
      iconPosition: 'left',
      route: '',
      subItem: [],
      position: 1,
      forPermission: ['AD', 'US', 'DO', 'AG', 'SA', 'DI']
    });

    ContentSideItem.subItems =
    [
    //   new SidenavItem({
    //   name: "toolbar.ROUTES.Contents.Support",
    //   route: "/contents/support",
    //   icon: "help_center",
    //   iconPosition: "right",
    //   subItem: [],
    //   parent: ContentSideItem,
    //   position: 1,
    // }),
      new SidenavItem({
      name: 'toolbar.ROUTES.Contents.ClinicSupport',
      route: '/contents/support/clinic',
      icon: 'add_box',
      iconPosition: 'right',
      subItem: [],
      parent: ContentSideItem,
      position: 1,
    }),
    // new SidenavItem({
    //   name: "toolbar.ROUTES.Contents.NuvolaWebSupport",
    //   route: "/contents/support/nuvola",
    //   icon: "public",
    //   iconPosition: "right",
    //   subItem: [],
    //   parent: ContentSideItem,
    //   position: 2,
    // }),
    // new SidenavItem({
    //   name: "toolbar.ROUTES.Contents.Shopping",
    //   route: "/contents/shopping",
    //   icon: "shopping_cart",
    //   iconPosition: "right",
    //   subItem: [],
    //   parent: ContentSideItem,
    //   position: 3,
    //   forPermission: ["AD", "EX", "US", "DO"]
    // }),
    new SidenavItem({
      name: 'toolbar.ROUTES.Contents.News',
      route: '/contents/news',
      icon: 'feed',
      iconPosition: 'right',
      subItem: [],
      parent: ContentSideItem,
      position: 4,
      contentName: 'news',
      badgeContent: this._contentService.getContentHolderCountValue('news')
    }),
    new SidenavItem({
      name: 'toolbar.ROUTES.Contents.Courses',
      route: '',
      externalLink: 'https://www.biomax.it/corsi-categoria/ortodonzia/ ',
      icon: 'school',
      iconPosition: 'right',
      subItem: [],
      parent: ContentSideItem,
      position: 5
    }),
    new SidenavItem({
      name: 'toolbar.ROUTES.Contents.Webinar',
      route: '',
      externalLink: 'https://www.biomax.it/webinar/',
      icon: 'ondemand_video',
      iconPosition: 'right',
      subItem: [],
      parent: ContentSideItem,
      position: 6
    }),
    // new SidenavItem({
    //   name: 'toolbar.ROUTES.Contents.Shopping',
    //   route: '/contents/shopping',
    //   icon: 'shopping_cart',
    //   iconPosition: 'right',
    //   subItem: [],
    //   parent: ContentSideItem,
    //   position: 7,
    //   forPermission: ['DO']
    // })
  ];

    // var NewDoctorSideItem = new SidenavItem({
    //   name: "toolbar.ROUTES.NewDoctor",
    //   route: '',
    //   icon:'person_add_alt',
    //   subItems: [ ],
    //   position: 1,
    //   routerLinkActiveOptions: {
    //     exact: true
    //   },
    //   forPermission:["AD", "EX", "US"],
    //   isButton:true
    // });

    const NewTreatmentSideItem = new SidenavItem({
      name: 'toolbar.ROUTES.NewTreatment',
      icon: 'source',
      iconPosition: 'left',
      route: '',
      subItem: [],
      position: 1,
      forPermission: ['AD', 'EX', 'US', 'DO'],
      forGroups: ['AD', 'ST', 'ED', '' ]
    });

    NewTreatmentSideItem.subItems = [
      new SidenavItem({
        name: 'toolbar.ROUTES.NewPatient',
        route: '/newpatient',
        icon: 'person_add',
        subItems: [],
        position: 1,
        iconPosition: 'right',
        routerLinkActiveOptions: {
          exact: true
        },
        parent: NewTreatmentSideItem,
        forPermission: ['EX', 'US', 'DO']
      }),
      new SidenavItem({
        name: 'toolbar.ROUTES.NewQuote',
        route: '/newquote',
        icon: 'post_add',
        subItems: [],
        iconPosition: 'right',
        position: 2,
        routerLinkActiveOptions: {
          exact: true
        },
        parent: NewTreatmentSideItem,
        forPermission: ['DO']
      }),
    ];
    const QuotesideItem =
      new SidenavItem({
        name: 'toolbar.ROUTES.Quotes',
        route: '/quotes',
        icon: 'edit_note',
        subItems: [],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        },
        forPermission: ['US', 'DO', 'AG', 'SA', 'DI']
      });

    // var LiveChatSideItem = new SidenavItem({
    //   name: "toolbar.ROUTES.LiveChat",
    //   route: '/livechat',
    //   icon: 'headset_mic',
    //   subItems: [],
    //   position: 1,
    //   routerLinkActiveOptions: {
    //     exact: true
    //   },
    //   //forPermission: ["AD", "EX", "US", "DO", "AG", "SA", "DI"]
    //   forPermission: ["DO"]
    // });

    const TreatmentReportItem = new SidenavItem({
      name: 'toolbar.ROUTES.TreatmentReport',
      route: '/treatments-report',
      icon: 'find_in_page',
      subItems: [],
      position: 1,
      routerLinkActiveOptions: {
        exact: true
      },
      forPermission: ['SA', 'DI', 'AG', 'EX']
    });

    const UsersSideItem = new SidenavItem({
      name: 'toolbar.ROUTES.Users',
      route: '/users',
      icon: 'people',
      subItems: [],
      position: 1,
      routerLinkActiveOptions: {
        exact: true
      },
      // forPermission: ["AD", "EX"]
      forPermission: ['AD', 'EX', 'US', 'DI', 'SA', 'AG'],
      forGroups: ['AD', 'ST', '', 'SH', 'CU' ]
    });

    const PlanningSideItem = new SidenavItem({
      name: 'toolbar.ROUTES.Planning',
      route: '/planning',
      icon: 'event',
      subItems: [],
      position: 1,
      routerLinkActiveOptions: {
        exact: true
      },
      // forPermission: ["US", "AD", "EX"],
      forPermission: ['US'],
      // forGroups: ["EX", "BX", "P3", "QA", "RV", "SH", "TF", "AD", ""]
      forGroups: ['EX', 'BX', 'P3', 'QA', 'RV', 'SH', 'TF' ]
    });

    const CalendarSideItem = new SidenavItem({
      name: 'toolbar.ROUTES.Calendar',
      route: '/calendar',
      icon: 'access_alarm',
      subItems: [],
      position: 1,
      routerLinkActiveOptions: {
        exact: true
      },
      forPermission: ['AD', 'US']
    });

    // var ShoppingSideItem = new SidenavItem({
    //   name: "toolbar.ROUTES.Contents.Shopping",
    //   route: "/contents/shopping",
    //   icon: "shopping_cart",
    //   subItem: [],
    //   position: 1,
    //   routerLinkActiveOptions: {
    //     exact: true
    //   },
    //   forPermission: ["AD", "EX", "US", "DO"]
    // });

    // tslint:disable-next-line:max-line-length
    // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(Dashboard));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(NewTreatmentSideItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(TreatmentSideItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(QuotesideItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(UsersSideItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(PlanningSideItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(CalendarSideItem));
    // this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(LiveChatSideItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(TreatmentReportItem));
    this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(ContentSideItem));

    this.store.dispatch(new fromSidenav.AddSidenavItemAction(Dashboard));
    if (this.currentUser.role !== 'EX' && this.currentUser.executiveArea !== 'P') {
      this.store.dispatch(new fromSidenav.AddSidenavItemAction(NewTreatmentSideItem));
    }
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(TreatmentSideItem));
    // this.store.dispatch(new fromSidenav.AddSidenavItemAction(ShoppingSideItem));
    // this.store.dispatch(new fromSidenav.AddSidenavItemAction(NewDoctorSideItem));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(QuotesideItem));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(UsersSideItem));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(PlanningSideItem));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(CalendarSideItem));
    // this.store.dispatch(new fromSidenav.AddSidenavItemAction(LiveChatSideItem));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(TreatmentReportItem));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(ContentSideItem));
  }

  ngOnDestroy() {
    this._routerEventsSubscription.unsubscribe();
  }

  openCourses() {
    console.log('openCourses');
  }
}
