import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime } from 'rxjs/operators';
import { TreatmentAdditionalFunctionalService } from '../../treatment-additional-functional.service';
import { CheckboxState, TypeIpr } from '../../../../../core/enums';

@Component({
  selector: 'nuvolaweb-lower-arch-table',
  templateUrl: './lower-arch-table.component.html',
  styleUrls: ['./lower-arch-table.component.scss']
})
export class LowerArchTableComponent implements OnInit, OnDestroy {

  @Input("lowerArch_1") lowerArch_1:any;
  @Input("lowerArch_2") lowerArch_2:any;
  @Input("lowerArch_3") lowerArch_3:any;

  @Input("forceDisable") forceDisable: boolean = false;
  @Input("hideTitle") hideTitle: boolean = false;

  private _subscribers: Subscription

  lowerTeeth: any[];

  public lowerArch_1Form:FormGroup;
  public lowerArch_2Form:FormGroup;
  public lowerArch_3Form:FormGroup;
  
  public allValues = ['', 'All 1', 'All 2', 'All 3', 'All 4', 'All 5', 'All 6', 'All 7', 'All 8', 'All 9', 'All 10', 'All 11', 'All 12' ];

  constructor(
    private fb:FormBuilder,
    private treatmentAdditionalFunctionalService: TreatmentAdditionalFunctionalService
  ) { }

  ngOnInit() {
    this.prepareTeeth();

    let _initForm = () => {
      return this.fb.group({
        all:0,
        col1:'',
        col2:'',
        col3:[""],
        col4:[""],
        col5:[""],
        col6:[""],
        col7:[""],
        col8:[""],
        col9:[""],
        col10:[""],
        col11:[""],
        col12:[""],
        col13:[""],
        col14:[""],
        col15:[""]
      });
    };

    [1,2,3].map((row) => {
      this[`lowerArch_${row}Form`] = _initForm();
    });

     if(this.treatmentAdditionalFunctionalService.getIsTabContentDisabledValue()){
      //  this.lowerArch_1Form.disable();
      //  this.lowerArch_2Form.disable();
      //  this.lowerArch_3Form.disable();
       this.lowerArch_1Form.controls['all'].disable();
       this.lowerArch_2Form.controls['all'].disable();
       this.lowerArch_3Form.controls['all'].disable();
     }

     this._subscribers =  this.treatmentAdditionalFunctionalService.isTabsContentDisabled.subscribe(value => {
      //  if(value){
      //    this.lowerArch_1Form.disable();
      //    this.lowerArch_2Form.disable();
      //    this.lowerArch_3Form.disable();
      //  }else{
      //    this.lowerArch_1Form.enable();
      //    this.lowerArch_2Form.enable();
      //    this.lowerArch_3Form.enable();
      //  }
      if (value){
        this.lowerArch_1Form.controls['all'].disable();
        this.lowerArch_2Form.controls['all'].disable();
        this.lowerArch_3Form.controls['all'].disable();  
      }else{
        this.lowerArch_1Form.controls['all'].enable();
        this.lowerArch_2Form.controls['all'].enable();
        this.lowerArch_3Form.controls['all'].enable();  
      }
     })

     if (this.forceDisable) {
      this.lowerArch_1Form.controls['all'].disable();
      this.lowerArch_2Form.controls['all'].disable();
      this.lowerArch_3Form.controls['all'].disable();  
    }
    
  }

  ngAfterViewInit(): void {
    this.initForms();

    [1,2,3].map((row) => {
      this[`lowerArch_${row}Form`].valueChanges.pipe().subscribe(value => {
        for(let prop in value) {
          if(prop == "all") {
            if (value[prop] != this[`lowerArch_${row}`][prop]) {
              this[`lowerArch_${row}`][prop] = (value[prop] as number);
              this[`lowerArch_${row}Form`].controls[prop].setValue(value[prop]);

              if(this[`lowerArch_${row}`][prop] == 0) {
                this.disableRow(row);
                this.resetRowCheckboxes(row);
              }
              if(this[`lowerArch_${row}`][prop] > 0) {
                this.enableRow(row);
              }
            }
          } else {
            if (value[prop].currentState != this[`lowerArch_${row}`][prop + 'Color']) {
              console.log('fire');
              this[`lowerArch_${row}`][prop + 'Color'] = (value[prop].currentState);
              this[`lowerArch_${row}Form`].controls[prop].setValue(value[prop]);
              
            }
            if (value[prop].typeIPR != this[`lowerArch_${row}`][prop + 'Value']) {
              this[`lowerArch_${row}`][prop + 'Value'] = (value[prop].typeIPR);
              this[`lowerArch_${row}Form`].controls[prop].setValue(value[prop]);
              
            }
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this._subscribers.unsubscribe();
  }

  resetRowCheckboxes(row: number) {
    let contextValue = {
      currentState: CheckboxState.White,
      typeIPR: TypeIpr.None
    };


    for (let controlName in this[`lowerArch_${row}Form`].controls) {
      if (!this[`lowerArch_${row}`].hasOwnProperty(controlName)) {
        this[`lowerArch_${row}`][controlName] = contextValue;
      }
    }
  }

  disableRow(row: number) {

    for (let index = 1; index <= 15; index++) {
      this[`lowerArch_${row}Form`].get('col'+ index).disable();
    }
  }

  enableRow(row: number) {
    this.setRowData(row);
    for (let index = 1; index <= 15; index++) {
      this[`lowerArch_${row}Form`].get('col'+ index).enable();
    }
  }

  setRowData(row: number) {
    for (let controlName in this[`lowerArch_${row}Form`].controls) {
      var contextValue:any = '';
      if (this[`lowerArch_${row}`].hasOwnProperty(controlName)) {
        contextValue = this[`lowerArch_${row}`][controlName];
        if (contextValue == 0) {
          this.disableRow(row);
        }
      } else {
        contextValue = {
          currentState: this[`lowerArch_${row}`][`${controlName}Color`],
          typeIPR: this[`lowerArch_${row}`][`${controlName}Value`]
        };
      }
      this[`lowerArch_${row}Form`].controls[controlName].setValue(contextValue);
    }
  }

  initForms() {
    for (let row = 1; row <= 3; row ++) {
      this.setRowData(row);
    }
  }

  public getColName(index: number):string {
    return `col${index + 1}`;
  }

  prepareTeeth() {
    this.lowerTeeth = [];
    
    this.lowerTeeth.push(
      { id: "4,8", src: "../../../../../assets/img/teeth/4,8.png", style: "16px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,7", src: "../../../../../assets/img/teeth/4,7.png", style: "16px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,6", src: "../../../../../assets/img/teeth/4,6.png", style: "16px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,5", src: "../../../../../assets/img/teeth/4,5.png", style: "9px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,4", src: "../../../../../assets/img/teeth/4,4.png", style: "9px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,3", src: "../../../../../assets/img/teeth/4,3.png", style: "7px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,2", src: "../../../../../assets/img/teeth/4,2.png", style: "3.5px", isSelected: false, icon: "", selectedValue: "" },
      { id: "4,1", src: "../../../../../assets/img/teeth/4,1.png", style: "4px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,1", src: "../../../../../assets/img/teeth/3,1.png", style: "3px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,2", src: "../../../../../assets/img/teeth/3,2.png", style: "3.5px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,3", src: "../../../../../assets/img/teeth/3,3.png", style: "7px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,4", src: "../../../../../assets/img/teeth/3,4.png", style: "8px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,5", src: "../../../../../assets/img/teeth/3,5.png", style: "9px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,6", src: "../../../../../assets/img/teeth/3,6.png", style: "16px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,7", src: "../../../../../assets/img/teeth/3,7.png", style: "16px", isSelected: false, icon: "", selectedValue: "" },
      { id: "3,8", src: "../../../../../assets/img/teeth/3,8.png", style: "16px", isSelected: false, icon: "", selectedValue: "" }
    )
  }

}
