import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AgmCoreModule } from '@agm/core';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWTTokenIterceptor } from './modules/security/services/jwt-token-interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { MatTooltipModule } from '@angular/material';
import { CanDeactivateGuard } from './pages/auth/can-deactivate.guard';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export function tokenProvider() {
  return localStorage.getItem('nuvola_session_token');
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'nuvolaweb'}),
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],
    EffectsModule.forRoot([]),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    AppRoutingModule,
    CoreModule,
    PagesModule,
    RouteHandlerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenProvider
      }
    }),
    TranslocoRootModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTTokenIterceptor,
      multi: true,
    },
    CanDeactivateGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
