import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { UsersService } from '../../users/users.service';
import { GenericAutocompleteInputComponent } from '../../components/generic-autocomplete-input/generic-autocomplete-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'elastic-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class RegisterComponent implements OnInit {
  //@ViewChild("countryField", {static:true}) countryField:GenericAutocompleteInputComponent;
  public registerForm:FormGroup;
  public addressesForm: FormGroup;
  public showHowManyPatients:boolean=false;

  public siteKey;
  private _lang: string;
  loaderReady: boolean;
  public hide = true;
  public autocompleteEndpoint:string = "/api/Country/AutoComplete";
  agents: any[] = [];
  isLoading: boolean = false;
  
  constructor(
    private router: Router,
    private fb:FormBuilder,
    private _usersService:UsersService,
    private snackBar:MatSnackBar,
    private _translocoService:TranslocoService,
    private _cb:ChangeDetectorRef
  ) {
    this.siteKey = environment.recaptchaSiteKeyClient;
  }

  ngOnInit() {
    this.addressesForm = this.fb.group({
      phone:["", [Validators.maxLength(30)]],
      address:[null, [Validators.required, Validators.maxLength(255)]],
      zipCode:[null, [Validators.required, Validators.maxLength(12)]],
      //countryCode:[null, Validators.required],
      countryCode:[null, Validators.required],
      //countryName:[null, Validators.required],
      activityName:[null, [Validators.maxLength(100)]],
      city:[null, Validators.required]
    });
    
    this.registerForm = this.fb.group({
      firstName:[null, [Validators.required, Validators.maxLength(50)]],
      lastName:[null, [Validators.required, Validators.maxLength(50)]],
      addressType:["R"],
      vat:[null, [Validators.required,Validators.maxLength(20)]],
      email:[null, [Validators.required, Validators.email]],
      password:[null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]],
      captcha:[null, [Validators.required]],
      active:[true],
      executiveArea:[""],
      idSuperAgent:[0],
      idDistributor:[0],
      userGroup:[""],
      idAgent:[0],
      language:['en'],
      question1: [false],
      // question2: [false],
      // question3: [false],
      question11: [null]
    });

    setTimeout(() => {
      this.registerForm.controls.addressType.setValue("R");
      this._cb.detectChanges();
    },350);
  }

  // selectCounty(country){
  //   this.addressesForm.controls.countryCode.setValue(country.key);
  //   this.addressesForm.controls.countryName.setValue(country.value);
  // }

  // clearSelected(){
  //   this.addressesForm.controls.countryCode.reset()
  //   this.addressesForm.controls.countryName.reset();
  // }

  register() {
    if(!this.registerForm.valid || !this.addressesForm.valid){
      this.registerForm.controls.firstName.markAsTouched({onlySelf:true});
      this.registerForm.controls.lastName.markAsTouched({onlySelf:true});
      this.registerForm.controls.vat.markAsTouched({onlySelf:true});
      this.registerForm.controls.email.markAsTouched({onlySelf:true});
      this.registerForm.controls.password.markAsTouched({onlySelf:true});
      this.registerForm.controls.captcha.markAsTouched({onlySelf:true});

      this.addressesForm.controls.address.markAsTouched({onlySelf:true});
      this.addressesForm.controls.zipCode.markAsTouched({onlySelf:true});
      this.addressesForm.controls.countryCode.markAsTouched({onlySelf:true});
      //this.addressesForm.controls.countryName.markAsTouched({onlySelf:true});
      this.addressesForm.controls.activityName.markAsTouched({onlySelf:true});
      this.addressesForm.controls.city.markAsTouched({onlySelf:true});

      //this.countryField.filterControl.markAsTouched({onlySelf:true});
      return;
    }

    this.isLoading = true;
    let lang = this._translocoService.getActiveLang();

    var request = {
      ...this.registerForm.value,
      isPublicRegistration: true,
      addresses:[
        {
          addressType:"R",
          ...this.addressesForm.value,
        },
        {
          addressType:"S",
          ...this.addressesForm.value,
        },
        {
          addressType:"I",
          ...this.addressesForm.value,
          "vat":this.registerForm.controls.vat.value
        }
      ],
      role:"DO",
      language: lang,
      question1: this.registerForm.controls.question1.value ? 'Y' : 'N',
      question11: this.registerForm.controls.question11.value == null ? 'N' : this.registerForm.controls.question11.value
      // question2: this.registerForm.controls.question2.value ? 'Y' : 'N',
      // question3: this.registerForm.controls.question3.value ? 'Y' : 'N',
    };

    this._usersService.createDoctor(request).subscribe(resp => {
      this.router.navigate(["/confirm-registration"]);
      this.snackBar.open(this._translocoService.translate("REGISTRATION.successMessage"), "X", {
        duration:3000,
        panelClass:"success",
        horizontalPosition:"right",
        verticalPosition:"top",
      } as MatSnackBarConfig);
      this.isLoading = false;
    },(error:HttpErrorResponse) => {
      if(error.status == 409){
        this.snackBar.open(this._translocoService.translate("REGISTRATION.errorMessageAlreadyExists"), "X", {
          duration:3000,
          panelClass:"warning",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
      }else{
        this.snackBar.open(this._translocoService.translate("REGISTRATION.errorMessage"), "X", {
          duration:3000,
          panelClass:"warning",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
      }
      this.isLoading = false;
    })

  }

  resolved(captchaResponse: string) {
    console.log(captchaResponse);
    this.registerForm.controls.captcha.setValue(captchaResponse);
    this.registerForm.controls.captcha.markAsTouched({onlySelf:true});
  }

  errored(capthaError) {
    console.warn(`reCAPTCHA error encountered: ${capthaError}`);
  }

  onZipCodeInput(event){
    var currentValue = "";
    if(this.registerForm.controls.zipCode){
      currentValue = this.registerForm.controls.zipCode.value;
      this.registerForm.controls.zipCode.reset();
      this.registerForm.controls.zipCode.setValue(currentValue.replace(/[^0-9.]/g, ""))
    }
  }

  isButtonDisabled(){
    var result = true;
    if(this.registerForm.valid && this.addressesForm.valid){
      result =  false;
    }

    return result;
  }

  toggleAligners(event: MatSlideToggleChange){
    this.showHowManyPatients = event.checked;
    if (event.checked){
      this.registerForm.controls.question11.setValidators(Validators.required);
    }else{
      this.registerForm.controls.question11.clearValidators();
    }
  }
  
}