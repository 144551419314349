import {api} from "./api";

export const environment = {
  production: true,
  googleMapsApiKey: '',
  appName:"",
  env:"PROD",

  arcadLab: api.arcadLab,
  api:api,
  apiUrl:api.base,
  storageBaseUrl:api.storageBase,
  recaptchaSiteKeyClient:"6LeuuacUAAAAABwfssSKlQub6aZQ2tw1jCc9iUjQ",
};